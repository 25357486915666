import axios from "axios";
import { IListAvailableIntegrationResponseModel } from "./Models/IListAvailableIntegrationResponseModel";
import { auth } from "../AuthManager/AuthManager";
import { cm } from "../AuthManager/ConnectionManager";

export class AvailableIntegrationsAPI {
	public static async GetAvailableIntegrations(token:string): Promise<IListAvailableIntegrationResponseModel> {
		return (
			await axios.get<IListAvailableIntegrationResponseModel>(cm.AuthApiBase + "/availableintegration", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
		).data;
	}
}
