import { auth } from "./AuthManager";

export enum HostType {
	Auth,
	Time,
	Task,
	Export,
	Search,
	Report,
	Improve,
}

export class HostNameHelper {
	public static readonly HostPrefixes = {
		[HostType.Auth]: "manage-api",
		[HostType.Time]: "time-api",
		[HostType.Task]: "task-api",
		[HostType.Export]: "export",
		[HostType.Search]: "search",
		[HostType.Report]: "report",
		[HostType.Improve]: "improve",
	};

	public static readonly LocalhostPorts = {
		[HostType.Auth]: 5000,
		[HostType.Time]: 5001,
		[HostType.Task]: 5002,
		[HostType.Export]: 5002,
		[HostType.Search]: 5002,
		[HostType.Report]: 5003,
		[HostType.Improve]: 5003,
	};

	public static GetHost(requestHost: string, hostType: HostType): string {
		var regex = /^\w+(?:\-api)?(\-\w+)?\./;
		var host = regex.test(requestHost)
			? "https://" + requestHost.replace(regex, HostNameHelper.HostPrefixes[hostType] + "$1.")
			: "http://localhost:" + HostNameHelper.LocalhostPorts[hostType];
		return host;
	}
}

export class ConnectionManager {
	public AuthApiBase = HostNameHelper.GetHost(window.location.hostname, HostType.Auth) + "/api";

	public TimeApiBase = HostNameHelper.GetHost(window.location.hostname, HostType.Time) + "/api";

	public TaskApiBase = HostNameHelper.GetHost(window.location.hostname, HostType.Task) + "/api";

	public async Fetch(input: RequestInfo, init?: RequestInit) {
		const initClone = Object.assign({}, init);

		initClone.headers = Object.assign({}, { Authorization: `Bearer ${auth.Token}` }, initClone.headers);

		return fetch(input, initClone);
	}
}

export const cm = new ConnectionManager();
