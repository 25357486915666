import React, { useState, useContext, useEffect } from "react";
import { AccountScreenProps } from "./AccountScreen.Interface";
import "./AccountScreen.scss";
import { auth } from "../../AuthManager/AuthManager";
import { JwtModel } from "../../AuthManager/JwtModel";
import { IAvailableIntegrationResponseModel } from "../../API/Models/IAvailableIntegrationResponseModel";
import { AvailableIntegrationsAPI } from "../../API/AvailableIntegrationsAPI";
import { useManageApi } from "../../App";

export function AccountScreen(props: AccountScreenProps) {
	const [jwtData, setJwtData] = useState<JwtModel | null>(() => auth.GetJwtData());

	const [integrations, setIntegrations] = useState<IAvailableIntegrationResponseModel[]>([]);

	const { token } = useManageApi()

	useEffect(() => {
		console.debug("token changed", token)
		if (token !== null){
			console.debug("getting integrations");
			AvailableIntegrationsAPI.GetAvailableIntegrations(token).then((int) => {
				setIntegrations(int.availableIntegrations);
			});
		}
	}, [token]);

	const integrationLinks = integrations.map((ai) => (
		<div className="integration-item">
			<h3 className="integration-name">{ai.displayName}</h3>
			<a className="new-integration-link" href={`${ai.authUrl}`}>
				LINK TO {ai.displayName}
			</a>
		</div>
	));

	const [working, setWorking] = useState(false);

	return (
		<section className="account-screen">
			<section className="account-details">
				<div className="header">Account Details</div>
				<div>aud: {jwtData ? jwtData.aud : ""}</div>
				<div>org: {jwtData ? jwtData.org : ""}</div>
				<div>iss: {jwtData ? jwtData.iss : ""}</div>
			</section>
			<section className="integrations">
				<div className="header">Integrations</div>

				{/* TODO: Display current integrations, 
				only show integration button if that integration doesn't exist, 
				otherwise it will be a 'remove integration' button */}
				{integrationLinks}
			</section>
		</section>
	);
}
