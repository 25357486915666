import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "chronometric-dev.au.auth0.com";
const auth0ClientKey = process.env.REACT_APP_AUTH0_CLIENTKEY || "ZzGIUV5Nali05NwVyUREdrL18ibrDxnC";

ReactDOM.render(
	<Auth0Provider
		domain={auth0Domain}
		clientId={auth0ClientKey}
		redirectUri={window.location.origin}
	>
		<App />
	</Auth0Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
