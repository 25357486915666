import { JwtResponseModel } from "./JwtResponseModel";
import { LoginResultEnum } from "./LoginResultEnum";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { cm } from "./ConnectionManager";
import { JwtModel } from "./JwtModel";

export class AuthManager {
	private token: string | null = null;

	private IsLocalhost() {
		return window.location.hostname === "localhost";
	}

	public async Login(username: string, password: string, twoFactorCode?: string): Promise<LoginResultEnum> {
		try {
			const response = await cm.Fetch(`${cm.AuthApiBase}/jwt`, {
				method: "POST",
				body: JSON.stringify({ Username: username, Password: password }),
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) {
				switch (response.status) {
					case 403:
						return LoginResultEnum.BadCredentials;
					default:
						return LoginResultEnum.OtherError;
				}
			}
			const responseJson = (await response.json()) as JwtResponseModel;
			this.token = responseJson.token;

			return LoginResultEnum.Success;
		} catch (err) {
			console.error(err);

			if (this.IsLocalhost()) {
				return LoginResultEnum.Success;
			}
			return LoginResultEnum.OtherError;
		}
	}

	public IsUserLoggedIn() {
		const jwtData = this.GetJwtData();

		const jwtValid = jwtData && moment.unix(jwtData.exp).diff(moment()) > 0;

		return jwtValid || false; //|| this.IsLocalhost();
	}

	public GetJwtData() {
		if (this.token) return jwtDecode(this.token) as JwtModel;
		return null;
	}

	public get Token() {
		return this.token;
	}

	public get UserId() {
		const jwtData = this.GetJwtData();

		return (jwtData && jwtData.uid) || null;
	}

	public get UserGuid() {
		const jwtData = this.GetJwtData();

		return (jwtData && jwtData.uid) || null;
	}
}

export const auth = new AuthManager();
