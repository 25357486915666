import { useAuth0, User, withAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import "./App.css";
import { AccountScreen } from "./Components/AccountScreen/AccountScreen";

export function useManageApi() {
	const { isAuthenticated, getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
	const [userMetadata, setUserMetadata] = useState(null);
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		const getUserMetadata = async () => {
			try {
				const accessToken = await getAccessTokenSilently({
					audience: `https://chronometric.app`,
				});

				setToken(accessToken);

				const userDetailsByIdUrl = `https://manage-api-dev.chronometric.app/api/userinfo`;

				const metadataResponse = await fetch(userDetailsByIdUrl, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const user_metadata = await metadataResponse.json();

				setUserMetadata(user_metadata);
			} catch (e) {
				console.log(e);

				const accessToken = await getAccessTokenWithPopup({
					audience: `https://chronometric.app`,
				});

				setToken(accessToken);
			}
		};

		if (isAuthenticated) getUserMetadata();
	}, [isAuthenticated, getAccessTokenWithPopup]);

	return { token, userMetadata };
}

export function App() {
	const { isAuthenticated, loginWithRedirect, user, isLoading, logout } = useAuth0();
	const { userMetadata } = useManageApi();

	useEffect(() => {
		if (!isLoading && !isAuthenticated) loginWithRedirect();
	}, [isLoading, isAuthenticated]);

	return (
		<div className="App">
			<header className="App-header">
				<div className="auth-bit">
					Hi, {user ? user.name : null}!
					<button type="button" onClick={() => (isAuthenticated ? logout() : loginWithRedirect())}>
						{isAuthenticated ? "Logout" : "Login"}
					</button>
				</div>
				<h1>Chronometric</h1>
			</header>
			<main>
				{userMetadata ? <pre>{JSON.stringify(userMetadata, null, 2)}</pre> : "No user metadata defined"}
				{isAuthenticated && !isLoading && <AccountScreen></AccountScreen>}
			</main>
		</div>
	);
}
